<template>
  <div class="home">
    <div>
      <img src="../../../public/images/veecam/app/veepai1.png" lazy width="100%" height="100%">
      <img src="../../../public/images/veecam/app/veepai2.png" lazy width="100%" height="100%">
      <img src="../../../public/images/veecam/app/veepai3.png" lazy width="100%" height="100%">
      <img src="../../../public/images/veecam/app/veepai4.png" lazy width="100%" height="100%">
<!--      <img src="../../../public/images/veecam/app/veepai5.png" lazy width="100%" height="100%">-->
      <img src="../../../public/images/veecam/app/veepai6.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "app",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>